import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {StepRow} from "../../components/table";
import {CodeSnippet} from "../../components/snippet";
import {ApiLink} from "../../components/link";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Fetch Result Set(s)";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            To execute a command and process a result set you should do the following:
        </p>

        <table className="mb-3">
            <tbody>
            <StepRow name="Step 1." href="#create_command">Initialize SELECT command</StepRow>
            <StepRow name="Step 2." href="#bind_input_params">Bind input parameters (if needed)</StepRow>
            <StepRow name="Step 3." href="#execute_command">Execute the command</StepRow>
            <StepRow name="Step 4." href="#process_output">
                Process the result set (or {' '}<a href="#process_output_multiple">multiple</a>{' '} result sets)
            </StepRow>
            </tbody>
        </table>

        <p>
            For example, let's fetch rows from the table {' '}<code>EMPLOYEES (NAME CHAR(25), AGE INTEGER)</code>.
        </p>

        <KeyHeader id="create_command">Initialize SELECT Command</KeyHeader>
        <p>
            To execute a command we need two objects:
            {' '}<ApiLink>SAConnection</ApiLink>{' '}
            (connection object) and {' '}<ApiLink>SACommand</ApiLink>{' '} (command object):
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &con, 
    _TSA("SELECT NAME, AGE FROM EMPLOYEES WHERE AGE > :1"));
            `.trim()}
        </CodeSnippet>

        <p>
            The line above creates a command object {' '}<code>cmd</code>{' '} based on
            previously created and connected connection object {' '}<code>con</code>{' '}
            (for initializing {' '}<code>con</code>{' '} object see
            {' '}<Link to="/HowTo/connect/">Connect to database</Link>). Second parameter is
            the command text. This command will select all rows from the table {' '}<code>EMPLOYEES</code>{' '}
            where the value of field {' '}<code>AGE</code>{' '} more than some input value.
        </p>

        <KeyHeader id="bind_input_params">Bind Input Parameters</KeyHeader>
        <p>
            To associate a value with input variable use
            {' '}<ApiLink>{`SACommand::operator <<`}</ApiLink>:
        </p>
        <CodeSnippet>{`
cmd << 25L;
            `.trim()}
        </CodeSnippet>

        <p>
            This line sets the input parameter marked by {' '}<code>:1</code>{' '} to
            value {' '}<code>25</code>{' '}.
        </p>
        <p>
            The library provides several ways for binding input variables.
            To get more details see {' '}<Link to="/HowTo/bind/">Bind input parameters</Link>.
        </p>

        <KeyHeader id="execute_command">Execute the Command</KeyHeader>
        <p>
            To execute a command use {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} method:
        </p>
        <CodeSnippet>{`cmd.Execute();`}</CodeSnippet>
        <p>
            This command selects all rows from the table where field {' '}<code>AGE</code>{' '}
            value is greater than {' '}<code>25L</code>. The result of command execution is
            a set of rows corresponding to the request.
        </p>

        <p>
            To check whether a result set exists after the command
            execution use {' '}<ApiLink>SACommand::isResultSet</ApiLink>{' '}
            method:
        </p>
        <CodeSnippet>{`bool is_result = cmd.isResultSet();`}</CodeSnippet>

        <KeyHeader id="process_output">Process the Result Set</KeyHeader>
        <p>
            After the command execution a set of {' '}<ApiLink>SAField</ApiLink>{' '} objects
            is created implicitly. Each {' '}<ApiLink>SAField</ApiLink>{' '} object
            represents a column in the result set. To get the number of columns in
            the result set call {' '}<ApiLink>SACommand::FieldCount</ApiLink>{' '} method:
        </p>
        <CodeSnippet>{`int col_count = cmd.FieldCount();`}</CodeSnippet>

        <p>
            Each field can be referenced by its name or position. To get a
            field information (name, type, size, etc.) directly after the command
            execution you can call {' '}<ApiLink>SACommand::Field</ApiLink>{' '} method:
        </p>
        <CodeSnippet>{`SAField& field = cmd.Field(_TSA("age"));`}</CodeSnippet>

        <p>
            The line above associates the column {' '}<code>AGE</code>{' '} in the
            result set with {' '}<code>field</code>{' '} variable. We can do the same getting
            column {' '}<code>AGE</code>{' '} by its position in the result set:
        </p>
        <CodeSnippet>{`SAField& field = cmd.Field(2);`}</CodeSnippet>

        <p>
            A field value is updated only after row fetching. To fetch row
            by row from the result set use {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '}
            method:
        </p>
        <CodeSnippet>{`
while(cmd.FetchNext())
    printf("Name: %s, age: %d \\n",
            cmd.Field(_TSA("name")).asString().GetMultiByteChars(),
            cmd.Field(_TSA("age")).asInt32());
            `.trim()}
        </CodeSnippet>

        <p>
            In example above we obtained a field value accessing
            appropriated {' '}<ApiLink>SAField</ApiLink>{' '} object.
            Another way to get a field value is to use
            {' '}<ApiLink>SACommand::operator []</ApiLink>:
        </p>
        <CodeSnippet>{`
while(cmd.FetchNext())
    printf("Name: %s, age: %d \\n",
            cmd[1].asString().GetMultiByteChars(),
            cmd[2].asInt32());
            `.trim()}
        </CodeSnippet>

        <p>
            You can use {' '}<ApiLink>SAField</ApiLink>{' '} () operators
            to quickly access values. In that case you can perform fetching as shown below:
        </p>
        <CodeSnippet>{`
while(cmd.FetchNext())
{
    SAString sName = cmd.Field(_TSA("name"));
    int nAge = cmd.Field(_TSA("age"));
    printf("Name: %s, age: %d \\n", sName.GetMultiByteChars(), nAge);
}
                `.trim()}
        </CodeSnippet>

        <p>
            Or using field indexes:
        </p>
        <CodeSnippet>{`
while(cmd.FetchNext())
{
    SAString sName = cmd[1];
    int nAge = cmd[2];
    printf("Name: %s, age: %d \\n", sName.GetMultiByteChars(), nAge);
}
                `.trim()}
        </CodeSnippet>

        <p>
            Processing Long, BLob and CLob data can have some differences compared to processing other data types.
            See {' '}<Link to="/HowTo/blobs/">Handle Long/CLob/BLob</Link>{' '} to get more information.
        </p>

        <h5 id="process_output_multiple" className="mt-4">Handle Multiple Result Sets</h5>
        <p>
            It is possible to process more than one result set returned
            from a batch or stored procedure using SQLAPI++. To process multiple result sets you should do the
            following:
        </p>
        <CodeSnippet>{`
// Process first result set
while(cmd.FetchNext())
{
    // do something special with first result set
    SAString s = cmd[_TSA("fstr")];
    printf("fstr: %s\\n", s.GetMultiByteChars());
}
                    
// Process second result set
while(cmd.FetchNext())
{
    // do something special with second result set
    int n = cmd[_TSA("fnum")];
    printf("fnum: %d\\n", n);
}
                `.trim()}
        </CodeSnippet>

        <p>
            If number of result sets is not known at compile time we can process result sets
            while {' '}<ApiLink>SACommand::isResultSet</ApiLink>{' '}
            returns true:
        </p>
        <CodeSnippet>{`
int nResulSets = 0;
while(cmd.isResultSet())
{
    printf("Processing result set #%d", ++nResulSets);
    while(cmd.FetchNext())
    {
        // do something special with this result set
        SAString s = cmd[1].asString();
        printf("field value: %s\\n", s.GetMultiByteChars());
    }
}
                `.trim()}
        </CodeSnippet>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
